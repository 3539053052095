// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/dealList/DealListSmall.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/dealList/DealListSmall.tsx");
  import.meta.hot.lastModified = "1708611543481.562";
}
// REMIX HMR END

import { Box, Center, Container, Divider, Flex, Heading, Image, SkeletonText, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Tab, TabList, TabPanel, TabPanels, Tabs, Tag, TagLabel, TagLeftIcon, Text, Tooltip, VStack, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import { Link } from "@remix-run/react";
import { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { GermanNumber, parseHitToDealHit } from "~/utils/MeiliSearch";
import { HotIcon } from "../customIcons";
import { DealDate } from ".";
function chunkArray(array, chunkSize) {
  let results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }

  // console.log(results);
  return results;
}
export const DealListSmall = props => {
  _s();
  const {
    deals,
    title,
    filter,
    updateFilter,
    type
  } = props;
  const [sliderValue, setSliderValue] = useState(24);
  const [showTooltip, setShowTooltip] = useState(false);
  // const [hits, setHits] = useState<DealHit[]>([]);
  const [chunks, setChunks] = useState([]);
  const [isLargerThan768] = useMediaQuery("(min-width: 992px)");
  const [initFetched, setInitFetched] = useState(false);
  const [currentType, setCurrentType] = useState(type);
  const loadDealList = async value => {
    setSliderValue(value);
    type && (await fetch(`/api/sidebar/${type}/${value}`, {
      headers: {
        "Content-Type": "application/json" // Add this line
      }
    }).then(res => res.json()).then(data => {
      const parsedDeals = data.deals.hits.map(hit => parseHitToDealHit(hit));
      const dealsChunk = chunkArray(parsedDeals, 5);
      setChunks(dealsChunk);
    }).catch(console.error));
  };
  useEffect(() => {
    if (deals && deals.length > 0 && sliderValue == 24) {
      setChunks(deals ? chunkArray(deals, 5) : []);
    }
  }, [deals]);
  useEffect(() => {
    if (!initFetched && isLargerThan768) {
      setInitFetched(true);
      loadDealList(24);
    }
  }, [isLargerThan768]);
  useEffect(() => {
    if (currentType != type) {
      setChunks([]);
      loadDealList(24);
      setCurrentType(type);
    }
  }, [type]);
  const color = useColorModeValue("blue.100", "gray.500");
  const _selected = useColorModeValue("blue.500", "gray.100");
  return <>
      <Container p="4" backgroundColor={useColorModeValue("gray.100", "gray.700")} borderRadius="xl">
        <Box>
          <Text fontSize="lg">{title}</Text>
        </Box>
        <Flex pt={0} pb={2}>
          <Text as="b" fontSize="sm" color={useColorModeValue("pink.500", "pink.100")} w="100px" noOfLines={1} mr="2">
            {sliderValue}h
          </Text>
          <Slider w="100%" id="slider" defaultValue={sliderValue} min={1} max={48} colorScheme="teal" onChange={v => setSliderValue(v)} onChangeEnd={v => loadDealList(v)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}
        // mt={2}
        >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <Tooltip hasArrow bg="teal.500" color="white" placement="top" isOpen={showTooltip} label={`${sliderValue} h`}>
              <SliderThumb />
            </Tooltip>
          </Slider>
        </Flex>
        <Tabs variant="unstyled" align="center">
          <TabPanels textAlign={"left"}>
            {chunks.length == 0 && [0, 1, 2, 3, 4].map(n => <Box key={"skel-" + n} padding="2">
                  <SkeletonText mt="4" noOfLines={4} spacing="2" skeletonHeight="2" />
                </Box>)}
            {chunks.length > 0 && chunks.map((hits, index) => <TabPanel key={"tab-" + index} p={0}>
                  <VStack>
                    {hits.map(hit => <Box key={hit.id} w={"100%"}>
                        <Flex marginTop={{
                  base: "1",
                  sm: "1"
                }} w={"100%"} marginBottom={3}>
                          <Link to={`/deal/${hit.dealURL}`}>
                            <Box marginRight="3" position="relative" alignItems="center">
                              <Center width={{
                        base: "65px",
                        sm: "70px"
                      }} height={{
                        base: "65px",
                        sm: "70px"
                      }} zIndex="2" marginLeft={{
                        base: "0",
                        sm: "5%"
                      }} marginTop="2" padding={1} bgColor={"white"} borderRadius={"3"}>
                                <Center textDecoration="none" _hover={{
                          textDecoration: "none"
                        }} height="100%">
                                  <Image
                          // borderRadius="md"
                          loading="lazy" src={hit.titleImageThumb}
                          // srcSet={hit.srcSet}
                          alt={hit.title} objectFit="contain" width="100%" maxH="100%" />
                                </Center>
                              </Center>
                            </Box>
                          </Link>
                          <Link to={`/deal/${hit.dealURL}`}>
                            <Box justifyContent="center" marginTop={{
                      base: "3",
                      sm: "0"
                    }}>
                              <DealDate fontSize={14} date={hit.dealDate} />
                              <Heading marginTop="1" mb={1} as="h5" size="xs">
                                <Text noOfLines={{
                          base: 4,
                          md: 4
                        }} textDecoration="none" _hover={{
                          textDecoration: "none"
                        }}>
                                  <Tag size={"sm"} variant="subtle" colorScheme="red" mr="1">
                                    <TagLeftIcon boxSize="10px" as={HotIcon} fill={"red"} />
                                    <TagLabel>{hit.hotness}</TagLabel>
                                  </Tag>
                                  <Tag size={"sm"} key={hit.title + "-price"} colorScheme="green" mr="1">
                                    <GermanNumber value={hit.price} decimal={2} content="€" />
                                  </Tag>
                                  {hit.title}
                                </Text>
                              </Heading>
                            </Box>
                          </Link>
                        </Flex>
                        <Divider></Divider>
                      </Box>)}
                  </VStack>
                </TabPanel>)}
          </TabPanels>
          <TabList>
            {chunks.map((hits, index) => <Tab key={index} p="0" fontSize={"xl"} color={color} _selected={{
            color: _selected
          }} aria-label={"Scroll Tab " + index}>
                <BsDot />
              </Tab>)}
          </TabList>
        </Tabs>
      </Container>
    </>;
};
_s(DealListSmall, "qmUDCgdDdi01PEQWegxY6uWRe8E=", false, function () {
  return [useMediaQuery, useColorModeValue, useColorModeValue, useColorModeValue, useColorModeValue];
});
_c = DealListSmall;
var _c;
$RefreshReg$(_c, "DealListSmall");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;